import { template as template_4e61e4fa91d7436087438d64088a5f2f } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_4e61e4fa91d7436087438d64088a5f2f(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
