import { template as template_059f86c042ba4c1f9a7c72d221b2c61a } from "@ember/template-compiler";
const WelcomeHeader = template_059f86c042ba4c1f9a7c72d221b2c61a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
