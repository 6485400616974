import { template as template_81d3ae13f4a6413e87d33adee290527b } from "@ember/template-compiler";
const FKText = template_81d3ae13f4a6413e87d33adee290527b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
