import { template as template_0cd34431d99849c7a2ef7aeb056c2592 } from "@ember/template-compiler";
const FKLabel = template_0cd34431d99849c7a2ef7aeb056c2592(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
